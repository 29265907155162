import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { withPreview } from "gatsby-source-prismic";

const Page = styled.div`
  margin: 150px auto 78px auto;
  padding: 0 40px;

  @media (max-width: 767px) {
    margin: 76px 0 40px 0;
    padding: 0 20px;
  }

  & a:hover {
    color: #d7490c;
  }
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.06em;

  margin: 0 0 86px 0;

  max-width: 700px;

  font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
    sans-serif;

  & a {
    border-bottom: 2px solid #000;
  }

  & strong {
    font-family: "CaslonDoric-Bold", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.15em;

    font-weight: normal;
  }

  @media (max-width: 767px) {
    margin: 0 auto 40px auto;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

const PrivacyPolicy = ({ data }) => (
  <Page>
    <Helmet title={`Privacy Policy - The Seeking State`} />
    <Text
      dangerouslySetInnerHTML={{
        __html: data.prismicPrivacyPolicy.data.text.html,
      }}
    />
  </Page>
);

export default withPreview(PrivacyPolicy);

export const query = graphql`
  {
    prismicPrivacyPolicy {
      data {
        text {
          html
        }
      }
    }
  }
`;
